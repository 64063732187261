import deburr from "lodash/deburr";
import get from "lodash/get";
import set from "lodash/set";
import { PAGE_PATHS } from "../routes/routes";
import { OFFER_TYPE } from "./constants";

export const MSG_SPLIT_MARKER = "#$$#"; // for concatenating strings which should be displayed as single rows in a toast message
const BR_TAG_MARKER = "#BR#";

export function getInputValue(event) {
  if (typeof event === "string") {
    return event.trim();
  }

  const checked = event.target?.checked;
  if (checked !== undefined) {
    return checked;
  }

  const value = event.target?.value;
  if (typeof value === "string") {
    return value.trim();
  }

  return value;
}

/**
 * See lodash documentation for "get"
 * (Returns value from object identified by given argument "path", which can be a path representation where
 * object keys are separated by dots.)
 *
 * @param {object} obj
 * @param {string} path
 * @returns {any}
 */
export const getPropertyByPath = (obj, path) => {
  return get(obj, path);
};

/**
 * See lodash documentation for "set"
 * (Sets value of an object identified by given argument "path", which can be a path representation where
 * object keys are separated by dots.)
 *
 * @param {object} obj
 * @param {string} path
 * @param {any} value
 * @returns {object} The modified object.
 */
export const setPropertyByPath = (obj, path, value) => {
  return set(obj, path, value);
};

/**
 * Can handle message from Axios, simple string, array of strings, and string that contains pipe MSG_SPLIT_MARKER (for
 * messages which should be displayed as single rows within one toast).
 *
 * @param {object|array|string} message Object with "displayMessage" (returned by Axios) or "message" property (returned by GraphQL)
 *                              or string or array of strings.
 * @returns {object} A JSX object to be shown by notistack.
 */
export const handleToastMessage = (message) => {
  if (!message) return "An error occurred.";

  let messages = message.displayMessage ? message.displayMessage : message;

  messages =
    typeof messages === "string"
      ? messages.indexOf(MSG_SPLIT_MARKER) > -1
        ? messages.split(MSG_SPLIT_MARKER)
        : [messages]
      : Array.isArray(messages)
        ? messages
        : [messages];

  messages = messages.map((msg) =>
    typeof msg === "string" ? msg : String(msg),
  );

  messages = messages
    .join(`${MSG_SPLIT_MARKER}${BR_TAG_MARKER}${MSG_SPLIT_MARKER}`)
    .split(MSG_SPLIT_MARKER);
  return (
    <div>
      {messages.map((item, index) =>
        item === BR_TAG_MARKER ? (
          <br key={index} />
        ) : (
          <span key={index}>{item}</span>
        ),
      )}
    </div>
  );
};

export const getInitials = (firstName, lastName) => {
  const first = firstName && firstName.length > 0 ? firstName[0] : "";
  const last = lastName && lastName.length > 0 ? lastName[0] : "";
  return (first + last).toUpperCase();
};

export const isStringInteger = (input) => {
  if (typeof input !== "string") return false;
  input = input.trim();
  if (!input) return false;
  const number = Number.parseInt(input);
  return String(number) === input;
};

export const detectIds = (input, separators = [" ", ",", "|"]) => {
  if (typeof input !== "string") return false;

  if (!Array.isArray(separators)) {
    separators = [separators];
  }

  let idsDetected = false;
  let ids = [];

  for (const separator of separators) {
    ids = input.split(separator);
    if (ids.length > 1) {
      if (!ids.some((id) => !isStringInteger(id))) {
        idsDetected = true;
        break;
      }
    }
  }
  if (idsDetected) {
    return ids;
  }
  return false;
};

export const getOfferPath = (offerType, productId) =>
  offerType === OFFER_TYPE.OFW
    ? PAGE_PATHS.createOffer.replace(":productId?", productId ? productId : "")
    : PAGE_PATHS.createOfferVideo.replace(
        ":productId?",
        productId ? productId : "",
      );

export const includesDeburred = (searchString, partToInclude) => {
  return deburr(searchString?.toLowerCase()).includes(
    deburr(partToInclude?.toLowerCase()),
  );
};

export const compareDeburred = (first, second) => {
  return deburr(first?.toLowerCase()).localeCompare(
    deburr(second?.toLowerCase()),
  );
};
