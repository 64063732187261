import { gql } from "@apollo/client";

const GET_OFFER_CAMPAIGN = gql`
  query offerCampaign($id: ID!) {
    offerCampaign(cmsId: $id) {
      actionType
      adContent
      applicationTargeting {
        targetType
        apps {
          id
          name
          cmsId
          iconUrl
          platform
        }
      }
      attributionProvider
      attributionWindow
      bid
      capping {
        dailyBudget
        monthlyBudget
        lifetimeBudget
        dailyConversionCap
        monthlyConversionCap
        lifetimeConversionCap
        dailyInstallCap
        monthlyInstallCap
        lifetimeInstallCap
      }
      clickUrl
      comment
      complexity
      costModel
      countryGroupBudgets
      creatives {
        id
        name
        assets {
          id
          kind
          state
          name
          url {
            raw
            low
            medium
            high
          }
          aspectRatio
          duration
          height
          orientation
          size
          width
          createdAt
          updatedAt
          advertiser {
            id
            name
          }
        }
      }
      deviceTargeting {
        iosOsVersions {
          firstVersion
          lastVersion
        }
        androidOsVersions {
          firstVersion
          lastVersion
        }
        phoneTargeted
        tabletTargeted
        deviceManufacturers {
          kind
          lists {
            manufacturer
            models
          }
        }
      }
      dynamicPayouts
      enabled
      enabledAt
      enabledFromAdvertiser
      endDate
      iconUrl
      id
      igniteCampaignId
      instructionSteps
      locationTargeting {
        countryCodes
        global
      }
      minimumLatency
      multiPayout
      name
      offerSteps {
        description
        isPayout
        actionId
        payoutDistribution
        minimumLatency
      }
      offerTranslations {
        id
        localizedTitle
        localizedSteps
        localizedInstructionSteps
        localizedComment
        countryCodes
      }
      product {
        adContent
        advertiser {
          id
          name
          cmsId
        }
        appCategoryGenre
        enabled
        iconUrl
        id
        name
        platform
        samsungStore
        secondaryCategories
      }
      productId
      startDate
      title
      updatedAt
    }
  }
`;

export default GET_OFFER_CAMPAIGN;
