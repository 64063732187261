export const PageIds = {
  DASHBOARD: "DASHBOARD",
  CREATE_OFFER: "CREATE_OFFER",
  CREATE_OFFER_TYPE: "CREATE_OFFER_TYPE",
  CREATE_OFFER_VIDEO: "CREATE_OFFER_VIDEO",
  EDIT_OFFER: "EDIT_OFFER",
  EDIT_OFFER_VIDEO: "EDIT_OFFER_VIDEO",
  REPORTING: "REPORTING",
  ACCOUNT: "ACCOUNT",
  SUPPORT: "SUPPORT",
  LOGIN_DATA: "LOGIN_DATA",
  COMPANY_DATA: "COMPANY_DATA",
  EMAIL_NOTIFICATIONS: "EMAIL_NOTIFICATIONS",
  API_ACCESS: "API_ACCESS",
  OFFER_DETAILS: "OFFER_DETAILS",
  PRODUCT_DETAILS: "PRODUCT_DETAILS",
  MANAGEMENT_USERS: "MANAGEMENT_USERS",
  MANAGEMENT_SUPPORT: "MANAGEMENT_SUPPORT",
};
export const PageIdsWithDateRange = [
  PageIds.DASHBOARD,
  PageIds.PRODUCT_DETAILS,
  PageIds.OFFER_DETAILS,
];

export const DEVELOPER_PORTAL_URL =
  "http://developer.fyber.com/content/ios/advertising/advertiser-reporting-api/";
export const EDGE_REPORTS_URL = "https://console.fyber.com/reports/edge";
export const SERVICE_ORDER_URL =
  "https://www.fyber.com/wp-content/uploads/2020/07/Service_Order_ACP_for_Demand_Partners_under_Fyber_Media_GmbH.pdf";
export const GDPR_CCPA_URL =
  "https://www.fyber.com/wp-content/uploads/2019/09/Combined-GDPR-and-CCPA-Data-Protection-Addendum-for-Demand-Partners-September-2019-2.pdf";

export const USER_ROLES = {
  ADMIN: "admin",
  SOLUTION_ENGINEER: "solution_engineer",
  ACCOUNT_MANAGER: "account_manager",
  ADVERTISER_ADMIN: "advertiser_admin",
  ADVERTISER_USER: "advertiser_user",
};
export const USER_ROLE_NAME_MAPPING = {
  // Fyberians
  [USER_ROLES.ADMIN]: "Admin",
  [USER_ROLES.SOLUTION_ENGINEER]: "Solution engineer",
  [USER_ROLES.ACCOUNT_MANAGER]: "Account manager",
  // customers
  [USER_ROLES.ADVERTISER_ADMIN]: "Advertiser admin",
  [USER_ROLES.ADVERTISER_USER]: "Advertiser user",
};
export const FYBER_USER_ROLES = [
  USER_ROLES.ADMIN,
  USER_ROLES.SOLUTION_ENGINEER,
  USER_ROLES.ACCOUNT_MANAGER,
];

export const MIN_PASSWORD_LENGTH = 8;

// minutes until advertiser list is invalidated (in userStore)
export const ADV_LIST_MAX_AGE_MINUTES = 30;

export const TOP_APP_BAR_HEIGHT = 60;
export const TOP_TAB_BAR_HEIGHT = 41;

export const TOP_APP_BAR_COMPONENTS = {
  NAV_LINK: "NAV_LINK",
  DASHBOARD_LINK: "DASHBOARD_LINK",
  OFFER_DROPDOWN: "OFFER_DROPDOWN",
  PRODUCT_DROPDOWN: "PRODUCT_DROPDOWN",
  ADVERTISER_DROPDOWN: "ADVERTISER_DROPDOWN",
  BREADCRUMB_ITEM_DIVIDER: "BREADCRUMB_ITEM_DIVIDER",
  BREADCRUMB_ITEM: "BREADCRUMB_ITEM",
};

export const NAV_MENU_WIDTH = {
  closed: 57,
  open: 200,
};

export const BREADCRUMB_ADD_PRODUCT_TEXT = "Add product";
export const ADD_APP_BREADCRUMB_TEXT = "Add app";

export const InputTextErrorMessages = {
  FIELD_REQUIRED: "This field is required",
  WRONG_URL: "Please enter a correct URL",
  WRONG_VAL: "Please enter a valid value",
};

export const ASSET_ORIENTATION = {
  PORTRAIT: "portrait",
  LANDSCAPE: "landscape",
};

export const ASSET_TYPE = {
  VIDEO: "video",
  IMAGE: "image",
};

export const PRODUCT_TYPE = {
  MOBILE: "mobile",
  WEB: "web",
};

export const OFFER_TYPE = {
  OFW: "offer",
  VIDEO: "video",
};

export const PRODUCT_PLATFORM = {
  ANDROID: "android",
  WEB: "web",
  IOS: "ios",
  SAMSUNG: "samsung",
};

export const GRID_COLUMN_TYPES = Object.freeze({
  PRODUCT: Symbol("PRODUCT"),
  OFFER: Symbol("OFFER"),
  COUNTRY_GROUP: Symbol("COUNTRY_GROUP"),
  COUNTRY_GROUP_APPS: Symbol("COUNTRY_GROUP_APPS"),
});

// debounced search/filter delay in milliseconds
export const DEBOUNCE_DELAY_LOCAL = 300;
export const DEBOUNCE_DELAY_REMOTE = 800;

export const ACTION_TYPE_APP_ACTION = "app_action";
export const ACTION_TYPE_INSTALL = "install";
export const ACTION_TYPE_PURCHASE = "purchase"; // web
export const ACTION_TYPE_SURVEY = "survey_completion"; // web
export const ACTION_TYPE_DONATION = "donation";

export const COST_MODEL = {
  CPI: "cpi",
  CPE: "cpe",
  CPA: "cpa",
};

export const ACTION_TYPES_MOBILE = {
  [ACTION_TYPE_INSTALL]: {
    offer_task: "Download and Install",
    offer_type: COST_MODEL.CPI,
    cost_model: "Cost per install",
    id: 1,
    key: "install",
  },
  [ACTION_TYPE_APP_ACTION]: {
    offer_task: "In-App Action",
    offer_type: COST_MODEL.CPE,
    cost_model: "Cost per engagement",
    id: 2,
    key: "app_action",
  },
};

export const ACTION_TYPES_WEB = {
  lead_generation: {
    offer_task: "Lead Generation",
    offer_type: COST_MODEL.CPA,
    cost_model: "Cost per action",
    id: 1,
    key: "action",
  },
  [ACTION_TYPE_SURVEY]: {
    offer_task: "Survey Completion",
    offer_type: COST_MODEL.CPA,
    cost_model: "Cost per action",
    id: 2,
    key: "action",
  },
  [ACTION_TYPE_PURCHASE]: {
    offer_task: "Purchase / Payment Required",
    offer_type: COST_MODEL.CPA,
    cost_model: "Cost per action",
    id: 3,
    key: "action",
  },
  [ACTION_TYPE_DONATION]: {
    offer_task: "Donation",
    offer_type: COST_MODEL.CPA,
    cost_model: "Cost per action",
    id: 4,
    key: "donation",
  },
  registration: {
    offer_task: "Registration",
    offer_type: COST_MODEL.CPA,
    cost_model: "Cost per action",
    id: 5,
    key: "action",
  },
  free_trial: {
    offer_task: "Free Trial",
    offer_type: COST_MODEL.CPA,
    cost_model: "Cost per action",
    id: 6,
    key: "action",
  },
  quiz: {
    offer_task: "Quiz",
    offer_type: COST_MODEL.CPA,
    cost_model: "Cost per action",
    id: 7,
    key: "action",
  },
};

export const AD_CONTENT = {
  dating: "Dating",
  gambling: "Gambling",
  alcohol: "Alcohol",
  pharmaceutical: "Pharmaceutical and supplements",
  political: "Political and religion",
  other: "Other age restricted",
  unrestricted: "Not restricted",
};

export const OFFER_COMPLEXITY_EASY = {
  1: {
    displayValue: "5-60 min",
    level: "Easy",
    description: "less than 1 hour",
  },
};
export const OFFER_COMPLETION_RANGE = {
  ...OFFER_COMPLEXITY_EASY,
  50: {
    displayValue: "1-24 hrs",
    level: "Medium",
    description: "less than 1 day",
  },
  100: {
    displayValue: "more than 1 day",
    level: "Hard",
    description: "More than a day",
  },
};

export const TRACKING_PROVIDER_OTHER = "other_provider";

export const TRACKING_PROVIDER_MAPPING = {
  [TRACKING_PROVIDER_OTHER]: "Other / Server 2 Server",
  mat: "Mobile App Tracking (MAT) by Tune",
  adjust: "Adjust",
  appsflyer: "Appsflyer",
  kochava: "Kochava",
  singular: "Singular",
};

export const TRACKING_PROVIDERS_WEB = [
  {
    name: TRACKING_PROVIDER_MAPPING[TRACKING_PROVIDER_OTHER],
    value: TRACKING_PROVIDER_OTHER,
  },
];

export const TRACKING_PROVIDERS_MOBILE = [
  {
    name: "Mobile App Tracking (MAT) by Tune",
    value: "mat",
  },
  {
    name: "Adjust",
    value: "adjust",
  },
  {
    name: "Appsflyer",
    value: "appsflyer",
  },
  {
    name: "Kochava",
    value: "kochava",
  },
  {
    name: "Singular",
    value: "singular",
  },
  {
    name: TRACKING_PROVIDER_MAPPING[TRACKING_PROVIDER_OTHER],
    value: TRACKING_PROVIDER_OTHER,
  },
];

export const TRACKING_PROVIDERS_VIDEO = [
  {
    name: "Appsflyer",
    value: "appsflyer",
  },
];

export const TRACKING_URL_MACROS = {
  "[[UserId]]": "Fyber User ID",
  "[[User_Id]]": "Publisher User ID",
  "[[AppId]]": "Fyber Application ID",
  "[[AppBundleName]]": "Application name taken from the URL",
  "[[AppName]]": "Fyber underscored Application Name",
  "[[AppIdUid]]": "Fyber Application ID + Publisher User ID",
  "[[Sub-Id]]": "SubID (including hyphens)",
  "[[SubId]]": "SubID (without hyphens)",
  "[[GAID_SHA1]]": "Hashed values of ad identifier",
  "[[IDFA_SHA1]]": "Hashed values of ad identifier",
  "[[PublisherAppCategory]]": "Publisher App's Primary Category",
  "[[PublisherId]]": "Fyber Publisher ID",
  "[[PublisherName]]": "Publisher Name",
  "[[VirtualCurrencyName]]": "Name of the Virtual Currency",
  "[[VirtualCurrencyExchangeRate]]":
    "Number of units of virtual currency per USD",
  "[[AppleIdfa]]": "Apple ID for Advertising of the User",
  "[[Apple-Idfa]]": "Apple ID for Advertising of the User (with hyphens)",
  "[[GAID]]": "Google Advertising ID (without hyphens)",
  "[[GA-ID]]": "Google Advertising ID (with hyphens)",
  "[[Adv-Id]]": "Apple or Google Advertising ID of the User (with hyphens)",
  "[[ContentPageUrl]]":
    "Encoded URL of the page from which the request was made",
  "[[CreativeSetName]]": "Name of the creative set used",
  "[[IP]]": "The user's IP address",
  "[[Random]]": "A random positive-only 32-bit integer number",
  "[[Timestamp]]": 'ISO 8601 UTC-based timestamp (i.e. "2011-10-26T10:21Z")',
  "[[CountryCode]]": "User's country code",
  "[[CountryGroupName]]": "Name of micro bidding country group user belongs to",
  "[[LanguageCode]]": "TOC language code",
  "[[LandingPageId]]": "Landing Page ID",
  "[[Host]]": "Publisher Hostname",
  "[[RefererPath]]": "Location Pathname",
  "[[TimezoneOffset]]": "GMT offset in minutes",
  "[[OfwRank]]": "Position of the ad in Offer Wall",
  "[[GDPRPrivacyConsent]]": "GDPR user consent value",
  "[[CampaignName]]": "The title of the campaign",
  "[[DeviceId]]":
    "A unique identifier that is used to identify a single and specific mobile device",
  "[[Device-Id]]":
    "A unique identifier that is used to identify a single and specific mobile device",
  "[[DeviceModel]]": "The user's mobile device model",
  "[[DeviceOsVersion]]": "The user's mobile operating system version",
  "[[CACHEBUSTER]]":
    "Ensures that a fresh call is made to the ad server every time the code is executed",
  "[[LocaleCode]]": "Language country code",
  "[[ProgramUrl]]": "Click URL configured in the landing page",
  "[[RunConversionEvent]]": "",
  "[[VirtualCurrency]]": "The currency within the app",
  "[[AppStoreId]]": "Application ID in the Google or App store ",
  "[[PubAppStoreId]]": "Application bundle ID",
  "[[UserAgent]]":
    "String identifying the device or OS including vendor and version from which the user is running",
  "[[LimitedAdTracking]]":
    "Indicated if a user has decided to opt in or out of being targeted using the device ID",
  "[[COPPA]]": "Flags child-directed content",
  "[[ConnectionType]]": "WiFi or Network",
  "[[BlockedCategories]]":
    "Categories blocked by the publisher of the application",
  "[[AppStoreURL]]": "URL of the application in the Google or Play store",
  "[[ScreenHeight]]": "User’s screen height",
  "[[ScreenWidth]]": "User’s screen width",
  "[[AppStoreName]]": "Name of the app in the Google or Play store",
  "[[BidType]]": "The type of payment for a conversion (CPI, CPE etc.)",
  "[[Currency]]": "Bid currency, either USD or Euro",
  "[[BidValue]]": "The numeric value of the bid",
  "[[BidSplitValues]]":
    'Concatenated list of bids (in USD), joined together with ","',
  "[[ActionIds]]": 'Concatenated list of action Ids, joined together with ","',
};

//fakeId for BrowseList list tracking .

export const BROWSE_DEVICE_TARGETING_FORMAT = [
  {
    id: -11,
    name: "iOS",
    key: "ios",
    value: [],
    type: "slider",
    tag: "os",
  },
  {
    id: -2,
    name: "Android",
    key: "android",
    value: [],
    type: "slider",
    tag: "os",
  },
  {
    id: -3,
    key: "deviceType",
    name: "Device Type",
    value: [],
  },
];

export const OFFER_TOOLTIP_TEXTS = {
  offerName:
    "This is the campaign name, that can be passed in the [CampaignName] macro",
  offerTitle:
    "This is what users see on the main Offer Wall page as the title of your campaign",
  offerAttributionWindowInternal:
    "The timeframe between a click and a conversion, within which the conversion is attributed to the campaign, set to %{default} days by default. Changing this field for live campaigns will apply to clicks that occur after the change, clicks before the change will be subject to the previous attribution window.",
  offerAttributionWindowNonInternal:
    "The timeframe between a click and a conversion, within which the conversion is attributed to the campaign, set to %{default} days by default. Changing this field for live campaigns will apply to clicks that occur after the change, clicks before the change will be subject to the previous attribution window. To update contact your Account Manager.",
  adContent:
    "Ad content is being used for publisher's side restrictions and is automatically derived from the app store category and maturity ratings, but can be edited by the account manager",
  translations: "Add translation",
  igniteCampaignId:
    "To allow SingleTap flow on this offer you need to input a Via campaign ID. Without this ID Ignite users will be redirected to the Google Play store",
};
